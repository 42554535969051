import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default function HomeRedirect(){
const navigation = useNavigate()
useEffect(()=>{
    navigation("/bigc")
},[])
    return(
        <div></div>
    )
}