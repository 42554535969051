import IP1 from "../assets/fontawesome/image/ip1.png";
import IP2 from "../assets/fontawesome/image/ip2.png";
import IP3 from "../assets/fontawesome/image/ip3.png";
import IP4 from "../assets/fontawesome/image/ip4.png";
import C1 from "../assets/fontawesome/image/1.png";
import C2 from "../assets/fontawesome/image/c2.png";
import C3 from "../assets/fontawesome/image/c3.png";
import C4 from "../assets/fontawesome/image/c4.png";
import C5 from "../assets/fontawesome/image/c5.png";
import C6 from "../assets/fontawesome/image/c6.png";
import P1 from "../assets/fontawesome/image/p1.png";
import P2 from "../assets/fontawesome/image/p2.png";
import P3 from "../assets/fontawesome/image/p3.png";
import P4 from "../assets/fontawesome/image/p4.png";
import P5 from "../assets/fontawesome/image/p5.png";
import P6 from "../assets/fontawesome/image/p6.png";
import LOGIN1 from "../assets/fontawesome/image/login1.png";
import LOGIN2 from "../assets/fontawesome/image/login2.png";
import LOGIN3 from "../assets/fontawesome/image/login3.png";
import LOGIN4 from "../assets/fontawesome/image/login4.png";
import LOGIN5 from "../assets/fontawesome/image/login5.png";
import B1IOS from "../assets/fontawesome/image/b1-ios-guide.png";
import B2IOS from "../assets/fontawesome/image/b2-ios-guide.png";
import B3IOS from "../assets/fontawesome/image/b3-ios-guide.png";

export const permissions_iphone = [
  {
    id: 1,
    url: IP1,
    title: "Bước 1: Chọn mục “Cài Đặt” ngoài màn hình chính",
  },
  {
    id: 2,
    url: IP2,
    title: `Bước 2: Chọn Mục “Trình duyệt đang sử dụng” trong “Cài đặt”`,
  },
  {
    id: 3,
    url: IP3,
    title: "Bước 3: Nhấn Toggle để cấp quyền camera",
  },
  {
    id: 4,
    url: IP4,
    title: "Bước 4: Đã cấp quyền Camera",
  },
];
export const dataGuideJoin = [
  {
    id: 1,
    url: LOGIN1,
    title:
      "Bước 1: Nhấn vào mục Tài Khoản, nhập số điện thoại và tên để nhận mã OTP.",
  },
  {
    id: 2,
    url: LOGIN2,
    title: `Bước 2: Nhập mã OTP vào trang chính của website, chọn Banner tham gia chương trình.`,
  },
  {
    id: 3,
    url: LOGIN3,
    title: "Bước 3: Chụp hoá đơn và nhận vòng quay may mắn.",
  },
  {
    id: 4,
    url: LOGIN4,
    title:
      "Bước 4: Thực hiện thao tác quay thưởng và nhận thông báo trúng thưởng cho tới khi hết lượt quay.",
  },
  {
    id: 5,
    url: LOGIN5,
    title: "Bước 5: Kiểm tra quà trúng thưởng tại mục Quà Của Tôi.",
  },
];
export const permissions_android = [
  {
    id: 1,
    url: C1,
    title: "Bước 1: Chọn mục “Cài Đặt” ngoài màn hình chính",
  },
  {
    id: 2,
    url: C2,
    title: "Bước 2: Vào Mục “Ứng dụng” trong “Cài đặt”",
  },
  {
    id: 3,
    url: C3,
    title: "Bước 3: Chọn trình duyệt mà quý khách đang sử dụng",
  },
  {
    id: 4,
    url: C4,
    title: "Bước 4: Chọn phần “Quyền”",
  },
  {
    id: 5,
    url: C5,
    title: "Bước 5: Chọn mục “Máy ảnh”",
  },
  {
    id: 6,
    url: C6,
    title: "Bước 6: Chọn “Chỉ cho phép khi dùng ứng dụng”",
  },
];

//Ghim Website
export const image_ios = [
  {
    id: 1,
    url: B1IOS,
    title:
      "Bước 1: Bạn truy cập vào trang web bất kỳ với Safari. Tiếp theo, bạn nhấn vào biểu tượng Chia sẻ (1) trên Safari => Thêm vào màn hình chính (2)",
  },
  {
    id: 2,
    url: B2IOS,
    title:
      "Bước 2: Bạn truy cập vào trình duyệt web của Chrome. Tiếp theo, chọn thêm vào màn hình chính (2)",
  },
  {
    id: 3,
    url: B3IOS,
    title:
      "Bước 3: Bạn đặt tên cho shortcut trang web (1) => Thêm (2) là xong. Bạn quay trở lại màn hình chính và thấy biểu tượng của website trên đó là thành công",
  },
];
export const image_android = [
  {
    id: 1,
    url: B1IOS,
    title:
      "Bước 1: Bạn truy cập vào trình duyệt web của Chrome. Tiếp theo, bạn chạm vào biểu tượng Thêm (1)",
  },
  {
    id: 2,
    url: B2IOS,
    title:
      "Bước 2: Bạn truy cập vào trình duyệt web của Chrome. Tiếp theo, chọn thêm vào màn hình chính (2)",
  },
  {
    id: 3,
    url: B3IOS,
    title:
      "Bước 3: Bạn đặt tên cho shortcut trang web (1) => Thêm (2) là xong. Bạn quay trở lại màn hình chính và thấy biểu tượng của website trên đó là thành công",
  },
];
export const images_iphone = [
  {
    id: 1,
    url: IP1,
    title: "Bước 1: Chọn mục “Cài Đặt” ngoài màn hình chính",
  },
  {
    id: 2,
    url: IP2,
    title: `Bước 2: Chọn Mục “Trình duyệt đang sử dụng” trong “Cài đặt”`,
  },
  {
    id: 3,
    url: IP3,
    title: "Bước 3: Nhấn Toggle để cấp quyền camera",
  },
  {
    id: 4,
    url: IP4,
    title: "Bước 4: Đã cấp quyền Camera",
  },
];
export const images_android = [
  {
    id: 1,
    url: C1,
    title: "Bước 1: Chọn mục “Cài Đặt” ngoài màn hình chính",
  },
  {
    id: 2,
    url: C2,
    title: "Bước 2: Vào Mục “Ứng dụng” trong “Cài đặt”",
  },
  {
    id: 3,
    url: C3,
    title: "Bước 3: Chọn trình duyệt mà quý khách đang sử dụng",
  },
  {
    id: 4,
    url: C4,
    title: "Bước 4: Chọn phần “Quyền”",
  },
  {
    id: 5,
    url: C5,
    title: "Bước 5: Chọn mục “Máy ảnh”",
  },
  {
    id: 6,
    url: C6,
    title: "Bước 6: Chọn “Chỉ cho phép khi dùng ứng dụng”",
  },
];

export const guideGame = [
  {
    id: 1,
    url: P1,
    title: `Dùng Camera điện thoại quét hình ảnh chương trình "bản đồ Việt Nam" hoặc "cụm sản phẩm P&G" trên bảng thông báo.`,
  },
  {
    id: 2,
    url: P2,
    title: "Chơi game săn voucher",
  },
  {
    id: 3,
    url: P3,
    title: `Hiển thị tổng mệnh giá Voucher đã săn được, bấm Quét hóa đơn và Mua hàng để ĐÓNG GÓP CÂY và CÀO VOUCHER.`,
  },
  {
    id: 4,
    url: P4,
    title: `Chụp hình hóa đơn” để nhận Voucher hấp dẫn từ P&G`,
  },
  {
    id: 5,
    url: P5,
    title: `Cào phần tráng bạc tại màn hình.`,
  },
  {
    id: 6,
    url: P6,
    title: `Hiện phần quà của bạn`,
  },
];