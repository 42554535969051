import BG_TOP from "../../assets/fontawesome/image/bg-lst-umx-top.png"
import BG_BOTTOM from "../../assets/fontawesome/image/bg-lst-umx-bottom.png"
import BG_MIDDLE from "../../assets/fontawesome/image/bg-lst-umx-middle.png"
import CAO_URBOX from "../../assets/fontawesome/image/cao-urbox.png"
import POPUP_RECEIPT from "../../assets/fontawesome/image/popup-receipt.png"
import BTN_LONG from "../../assets/fontawesome/image/btn-umx-long.png"
import SCRATCH from "../../assets/fontawesome/image/scratch.png"
import { useEffect, useState } from "react"
import ScratchCard from 'react-scratchcard-v2';
import { CUSTOM_BRUSH_PRESET } from 'react-scratchcard-v2';
import "../SpinFreeFire/SpinFreeFire.css"
import BTN_UMX from "../../assets/fontawesome/image/btn-umx.png"
import { useNavigate, useParams } from "react-router-dom"
import { luckyDrawService } from "../../services/apiService/LuckyDraw"
import { WHEEL_PHANTHUONG } from "../../utils/KeyConstant"

export default function ListGiftScratch(){
    const navigation = useNavigate()
    const { id } = useParams();
    const [giftReceipt, setGift] = useState()
    const [countDraw, setCountDraw] = useState(0)
    const appCode = localStorage.getItem("CAMPAIGN_CODE");
  useEffect(() => {
    luckyDrawService
      .postDrawLuckyGift(id)
      .then((dataResponse) => {
        console.log(dataResponse);
        let count_draw = dataResponse.count_draw;
        localStorage.setItem(
            WHEEL_PHANTHUONG,
            JSON.stringify(dataResponse.list_win[count_draw])
          );
        setGift(dataResponse)
        const checkCountDraw =  dataResponse?.gift_no- dataResponse?.count_draw
        setCountDraw(checkCountDraw)
        if(checkCountDraw === 0){
            navigation(`/${appCode}`)
        }
    })}
,[])
const handleCountDraw =()=>{
    luckyDrawService
      .postCountDraw(id)
      .then((response) => {
        console.log(response)
        setCountDraw(response?.gift_no - response?.count_draw)
        setTimeout(()=>{
            navigation(`/anouce-scratchcard/${id}`);
        },1000)
      })
}
    return(
        <div className="relative ">
           {/* <div className="mt-20">
                <img src={BG_TOP}/>
           </div>
           <div className='relative -top-[2px] overflow-hidden'>
                <img src={BG_MIDDLE}/>
                <div className="absolute top-0 px-16 pt-2">
                    <div className="containerNotify__backgroundScratch relative top-1/2">
                        <div className="containerNotify__background-listnotiScratch relative top-1/2 -translate-y-1/2">
                            
                            {
                                giftReceipt?.list_win?.map((res)=>(
                            <div className="relative top-0 flex justify-center mb-2 z-10">
                                <img src={CAO_URBOX} className="w-full"/>
                                <div className="absolute top-1 left-1/2 -translate-x-1/2 flex justify-center w-full custom-scratch rounded-sm">
                                    <ScratchCard
                                        width={ 200 }
                                        height={40}
                                        image={SCRATCH}
                                        brushSize={5}
                                        finishPercent={80}
                                        customBrush={CUSTOM_BRUSH_PRESET}
                                        // customCheckZone={}
                                        onComplete={() => handleCountDraw()}
                                    >
                                        <div className="bg-white text-center rounded-md"
                                        >
                                        <div className="h-10 flex items-center justify-center font-bold-mon text-[#492453] w-full uppercase leading-5">
                                            {res?.gift}
                                            </div>
                                        </div>
                                    </ScratchCard>
                                </div>
                            </div>
                                ))
                            }
                            <div className="h-20"></div>
                        </div>
                    </div>
                </div>
           </div>
           <div className='relative -top-[4px] z-40 w-full'>
                <img src={BG_BOTTOM}/>
                <div className="flex w-full justify-center absolute -bottom-4">
                    <button>
                        <div className="relative">
                        <img src={BTN_UMX}/>
                        <div className="absolute top-1/2 -translate-y-1/2 text-white font-regular-mon text-[18px] left-1/2 -translate-x-1/2 w-full -mt-1">
                        Bạn có <span className="font-bold-mon bg-[#347c46] px-3 py-1 rounded-2xl">{countDraw}</span> lượt cào
                        </div>
                        </div>
                    </button>
                </div>
           </div> */}
           <div className="relative top-32 left-1/2 -translate-x-1/2">
                <img src={POPUP_RECEIPT} className="w-full"/>
                <div className="absolute top-[45%] -translate-y-1/4 w-10/12 left-1/2 -translate-x-1/2">
                    <div className="containerNotify__backgroundScratch relative top-1/2">
                        <div className="containerNotify__background-listnotiScratch relative top-1/2 -translate-y-1/2">
                            {
                                giftReceipt?.list_win?.map((res)=>(
                            <div className="relative top-0 flex justify-center mb-2 z-10">
                                <img src={CAO_URBOX} className="w-full"/>
                                <div className="absolute top-1 left-1/2 -translate-x-1/2 flex justify-center w-full custom-scratch rounded-xl">
                                    <ScratchCard
                                        width={300}
                                        height={90}
                                        image={SCRATCH}
                                        brushSize={5}
                                        finishPercent={60}
                                        customBrush={CUSTOM_BRUSH_PRESET}
                                        onComplete={() => handleCountDraw()}
                                    >
                                        <div className="bg-white text-center rounded-lg"
                                        >
                                            <div className="h-[89px] flex items-center justify-center font-bold-mon text-[#492453] w-full uppercase leading-5">
                                                {res?.gift}
                                            </div>
                                        </div>
                                    </ScratchCard>
                                </div>
                            </div>
                                ))
                            }
                            <div className="h-20"></div>
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-center absolute -bottom-4">
                    <button>
                        <div className="relative">
                        <img src={BTN_LONG}/>
                        <div className="absolute top-1/2 -translate-y-1/2 text-white font-regular-mon left-1/2 -translate-x-1/2 w-full -mt-1">
                            Bạn có <span className="font-bold-mon bg-[#347c46] px-3 py-1 rounded-2xl">{countDraw}</span> lượt cào
                        </div>
                        </div>
                    </button>
                </div>
           </div>
        </div>
    )
}