import { useNavigate, useParams } from "react-router-dom";
import HEADER from "../../assets/fontawesome/image/umx-details.png"
import UMX from "../../assets/fontawesome/image/umx1.png"
import LOGOCT from "../../assets/fontawesome/image/logoct.png"
import SHOOTVOUCHER from "../../assets/fontawesome/image/shootvoucher.png"
import BTN_UMX from "../../assets/fontawesome/image/btn-umx.png"
import BG_VIDEO from "../../assets/fontawesome/image/bg-video.png"
import { useEffect, useState } from "react";
import { campaignServices } from "../../services/apiService/campaignServices";
import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin"
import Loading from "../../component/Loading/Loading";

export default function UMXHotdetails(){
  const appCode = localStorage.getItem("CAMPAIGN_CODE");
  const redirectLogin = window.location.pathname
  console.log(redirectLogin)
  localStorage.setItem("CAMPAIGN_CODE", "bigc");
  const [campaignDetail, setCampaignDetail] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true)
  const { id } = useParams();
  const navigation = useNavigate();
  const { token, phone } = JSON.parse(
    localStorage.getItem("USER_DATA_LOCAL") || "{}"
  );
  const handleBack = () => {
    navigation(`/${appCode}`);
  };
  const getCampaignDetail = (id) => {
    campaignServices
      .getCampaignDetailApi(+id)
      .then((res) => {
        console.log(res)
        setCampaignDetails(res)
        const id_video = res?.campaign_clip_url?.split("watch?v=")[1]
        console.log(id_video)
        const clip =
        res?.campaign_clip_url?.replace("watch?v=", "embed/") + `?autoplay=1&mute=1&loop=1&playlist=${id_video}`;
        console.log(clip)
        setCampaignDetail(clip);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCampaignDetail(id);
  }, []);
  const hanldeRedirectTakePhoto = () =>{
    if(token){
      navigation(`/game-ar/${id}`)
    }else{
      setPopup(true)
      localStorage.setItem("REDIRECT_LOGIN", `/game-ar/${id}`)
    }  
  }
  const hanldeTakePhoto = () =>{
    if(token){
    navigation(`/guide-takeaphoto/${id}`)
    }else{
      setPopup(true)
      localStorage.setItem("REDIRECT_LOGIN", `/guide-takeaphoto/${id}`)
    }  
  }

    return(
        <div>
          {
            loading ? <Loading/>:null
          }
            <div
                className="z-20 fixed top-8 left-5 h-[58px] w-[58px] cursor-pointer"
                onClick={handleBack}
            >
              <div className="containerNotify__header-btnBack h-[40px] w-[60px] cursor-pointer"></div>
            </div>
            <div><img src={campaignDetails?.campaign_banner}/></div>
            <div className="w-full flex justify-center mt-2">
                <div className="rounded-2xl mt-[2px] max-w-[100vw] h-[213px] text-xs m-auto relative group">
                  <div className="relative">
                    <div className="px-3">
                      <img src={BG_VIDEO} className="w-screen h-[230px]"/>
                    </div>
                      <iframe
                      width="100%"
                      height="195px"
                      style={{ fontSize: "3px", borderRadius: "20px", width:"80vw", maxWidth:"430px" }}
                      src={campaignDetail}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                      className="absolute top-4 left-[49%] -translate-x-1/2"
                    ></iframe>
                  </div>
                </div>
            </div>
            <div className="relative top-12">
              <img src={UMX}/>
            </div>
            <div className="mt-16">
                <div className="bg-tree px-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: campaignDetails?.campaign_banner_detail,
                      }}
                      className="mt-5 px-2 bg-[#ffffff] opacity-90 border-bg-umx text-[14px] py-3 box-shadow-umx"
                    ></div>
                    <div className="mt-5 text-[10px]">
                      <img src={LOGOCT}/>
                      <div className="mt-3">
                        * Không áp dụng:
                        <div>
                          Dầu gội Head&Shoulders Bạc Hà Mát Lạnh 625ml, Pantene Ngăn Rụng Tóc 650ml, Rejoice Siêu Mềm Mượt 48H 630ml,
                        </div>
                        <div>
                          Nước xả vải Downy Huyền Bí 22L, Downy Đam Mê 22L, Downy Nắng Mai 23L,
                        </div>
                        <div>
                          Nước giặt Ariel Cửa Trên Hương Downy Nước Hoa 3.2kg/Ariel Cửa Trên Sạch Bẩn Hương Downy Nước Hoa 3.2kg, Ariel CỬa Trên Sạch Bẩn Nắng Sớm 3.5kg
                        </div>
                        <div>
                          Các sản phẩm đồng giá 99k, 119k, các sản phẩm có khuyến mãi tặng kèm, chương trình khuyến mãi hot tại cửa hàng.
                        </div>
                      </div>
                    </div>
                    <div className="h-20"></div>
                    <div className="flex justify-center mt-5 fixed bottom-0 left-1/2 -translate-x-1/2 w-full">
                      <button onClick={hanldeRedirectTakePhoto}>
                          <div className="relative">
                          <img src={SHOOTVOUCHER}/>
                          <div className="absolute top-1/2 -translate-y-1/2 text-white font-extrabold-mon 
                          text-[16px] left-1/2 -translate-x-1/2 w-full -mt-1">Săn Voucher</div>
                          </div>
                      </button>
                      <button onClick={hanldeTakePhoto}>
                          <div className="relative">
                          <img src={BTN_UMX}/>
                          <div className="absolute top-1/2 -translate-y-1/2 text-white font-extrabold-mon 
                          text-[16px] left-1/2 -translate-x-1/2 w-full -mt-1 leading-5">
                            Quét hoá đơn<br/> nhận quà
                          </div>
                          </div>
                      </button>
                    </div>
                </div>
               
            </div>
        {
          popup ?  <ConfirmPopupLogin 
          labelOK={"Đồng ý"} 
          labelCancel={"Đóng"} 
          titlePopup={"Vui lòng đăng nhập để tham gia"}
          handleOk={()=>{navigation("/login")}}
          handleCancel={()=>{setPopup(false)}}
          />:null
        }
        </div>
    )
}