import { useNavigate } from "react-router-dom"
import CERT from "../../assets/fontawesome/image/cert.png"

export default function CertTree(){
    const navigation = useNavigate()
    const handleRedirect = () =>{
        navigation("/bigc")
    }
    return(
        <div className="relative">
            <img src={CERT}/>
            <div>
                <button onClick={handleRedirect} className="bg-[#1D86FA] fixed bottom-10 text-white font-bold-mon text-[20px] left-1/2 -translate-x-1/2 w-56 py-3 rounded-lg">
                    Quay về trang chủ
                </button>
            </div>
        </div>
    )
}