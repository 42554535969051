/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import "./GuideTakePhoto.css";
import ArGame from "uommam-ar-game/dist/esm/components/ArGame";
import { useNavigate, useParams } from "react-router-dom";

const wait = (sec) => {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, sec);
    });
};

const GameAR = (props) => {
    const onStartGame = () => {
        //
        console.log("onStartGame");
    };
    const navigation = useNavigate();
    const { id } = useParams();
    

    /**
     * @param {{score:number}} data
     */
    const onEndGame = (data) => {
        //
        console.log("onEndGame", data);
    };

   

    const onClickScanBill = async () => {
        //
        console.log("opClickScanBill");

        // setMain(<></>);

        // await wait(2000);
        // navigation(`/guide-takeaphoto/${id}`);
        window.location.href = `${window.location.origin}/guide-takeaphoto/${id}`;

    };

    const onClickBackToHome = () => {
        //
        navigation("/");
        console.log("onClickBackToHome");
    };
    useEffect(()=>{
        localStorage.removeItem("REDIRECT_LOGIN");
    },[])
    const [main, setMain] = useState(
        <>
            <ArGame
                assets={[
                    //
                    "/assets/fonts/MontserratBlack.ttf",

                    "/assets/images-webp/game/images/ui-game-score.webp",
                    "/assets/images-webp/game/images/ui-game-time.webp",

                    "/assets/images-webp/game/images/end-btn-back.webp",
                    "/assets/images-webp/game/images/end-btn-quet.webp",
                    "/assets/images-webp/game/images/end-popup.webp",
                    "/assets/images-webp/game/images/tut-btn.webp",
                    "/assets/images-webp/game/images/tut-permission.webp",
                    "/assets/images-webp/game/images/tut-popup.webp",
                    "/assets/images-webp/game/images/tut-btn-dongy.webp",
                    "/assets/images-webp/game/images/ui-voucher-glow.webp",
                    "/assets/images-webp/game/images/ui-rotate.webp",
                    "/assets/images/game/images/pointer.gif",

                    "/assets/images-webp/game/markers/marker-prod.webp",
                    "/assets/images/game/markers/marker-prod.zpt",
                    "/assets/images-webp/game/markers/marker-vnmap.webp",
                    "/assets/images/game/markers/marker-vnmap.zpt",
                    "/assets/images-webp/game/markers/marker-prod-transparent.webp",
                    "/assets/images-webp/game/markers/marker-vnmap-transparent.webp",

                    "/assets/images/game/images/glow1.png",
                    "/assets/images/game/images/glow2.png",
                    "/assets/images/game/images/portal_ring_twisty.png",
                    "/assets/images/game/images/slash_circle02.png",
                    "/assets/images/game/images/magic_blast.png",
                    "/assets/images-webp/game/images/lightray2.webp",

                    "/assets/images-webp/game/images/voucher-1.webp",
                    "/assets/images-webp/game/images/skull2.webp",
                    "/assets/images-webp/game/images/skull_evil.webp",
                    "/assets/images-webp/game/images/skull_love.webp",
                    "/assets/images/game/module/zappar-threejs.js",

                    "/assets/images-webp/game/images/ribbon/vib-game-ribbon-01.webp",
                    "/assets/images-webp/game/images/ribbon/vib-game-ribbon-02.webp",
                    "/assets/images-webp/game/images/ribbon/vib-game-ribbon-03.webp",
                    "/assets/images-webp/game/images/ribbon/vib-game-ribbon-04.webp",
                    "/assets/images-webp/game/images/ribbon/vib-game-ribbon-05.webp",
                    "/assets/images-webp/game/images/ribbon/vib-game-ribbon-06.webp",

                    "/assets/images-webp/game/images/tree/1x/asset-13.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-14.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-15.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-17.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-18.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-19.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-21.webp",
                    "/assets/images-webp/game/images/tree/1x/asset-23.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-134x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-144x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-154x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-174x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-184x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-194x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-214x.webp",
                    "/assets/images-webp/game/images/tree/4x/asset-234x.webp",

                    "/assets/images-webp/game/images/shooting/2x/shooting-01.webp",
                    "/assets/images-webp/game/images/shooting/2x/shooting-02.webp",
                    "/assets/images-webp/game/images/shooting/2x/shooting-03.webp",
                    "/assets/images-webp/game/images/shooting/2x/shooting-04.webp",
                    "/assets/images-webp/game/images/ui-game-shooting.webp",

                    "/assets/images-webp/game/images/clouda-2x2-hardshadow-01.webp",
                    "/assets/images-webp/game/images/cloudb-2x2-hardshadow-03.webp",
                    "/assets/images-webp/game/images/cloudc-2x2-hardshadow-04.webp",
                    "/assets/images-webp/game/images/cloudd-2x2-hardshadow-02.webp",

                    "/assets/sounds/Battle4.mp3",
                    "/assets/sounds/Bow1.mp3",
                    "/assets/sounds/Fall.mp3",
                    "/assets/sounds/Gun1.mp3",
                    "/assets/sounds/Item3.mp3",
                    "/assets/sounds/Victory2.mp3",
                ]}
                // onInit={onInit}
                onStartGame={onStartGame}
                onEndGame={onEndGame}
                onClickScanBill={onClickScanBill}
                onClickBackToHome={onClickBackToHome}
            />
            <style global jsx>{`
                body,
                html {
                    overflow: hidden;
                }

                .GameSection * {
                    user-select: none;
                }

                .pointer-events-none {
                    pointer-events: none;
                }
                .fixed {
                    position: fixed;
                }
                .absolute {
                    position: absolute;
                }
                .relative {
                    position: relative;
                }
                .left-0 {
                    left: 0;
                }
                .left-1\/2 {
                    left: 50%;
                }
                .top-0 {
                    top: 0;
                }
                .top-1\/2 {
                    top: 50%;
                }
                .top-1\/3 {
                    top: 33.333333%;
                }
                .top-2\/3 {
                    top: 66.666667%;
                }
                .top-\[40\%\] {
                    top: 40%;
                }
                .z-0 {
                    z-index: 0;
                }
                .z-10 {
                    z-index: 10;
                }
                .z-20 {
                    z-index: 20;
                }
                .z-30 {
                    z-index: 30;
                }
                .z-50 {
                    z-index: 50;
                }
                .z-\[99999\] {
                    z-index: 99999;
                }
                .-mt-20 {
                    margin-top: -5rem;
                }
                .-mt-\[30px\] {
                    margin-top: -30px;
                }
                .-mt-\[40px\] {
                    margin-top: -40px;
                }
                .flex {
                    display: flex;
                }
                .grid {
                    display: grid;
                }
                .h-\[3\.2rem\] {
                    height: 3.2rem;
                }
                .h-\[3rem\] {
                    height: 3rem;
                }
                .h-full {
                    height: 100%;
                }
                .w-1\/2 {
                    width: 50%;
                }
                .w-11\/12 {
                    width: 91.666667%;
                }
                .w-3\/4 {
                    width: 75%;
                }
                .w-\[70\%\] {
                    width: 70%;
                }
                .w-\[80\%\] {
                    width: 80%;
                }
                .w-auto {
                    width: auto;
                }
                .w-full {
                    width: 100%;
                }
                .max-w-\[120px\] {
                    max-width: 120px;
                }
                .max-w-\[80px\] {
                    max-width: 80px;
                }
                .-translate-x-1\/2 {
                    --tw-translate-x: -50%;
                }
                .-translate-x-1\/2,
                .-translate-y-1\/2 {
                    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                }
                .-translate-y-1\/2 {
                    --tw-translate-y: -50%;
                }
                .scale-\[2\] {
                    --tw-scale-x: 2;
                    --tw-scale-y: 2;
                    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                }
                .resize {
                    resize: both;
                }
                .flex-col {
                    flex-direction: column;
                }
                .place-items-center {
                    place-items: center;
                }
                .items-center {
                    align-items: center;
                }
                .justify-center {
                    justify-content: center;
                }
                .justify-between {
                    justify-content: space-between;
                }
                .gap-6 {
                    gap: 1.5rem;
                }
                .bg-black\/\[\.6\] {
                    background-color: #0009;
                }
                .bg-white\/\[\.3\] {
                    background-color: #ffffff4d;
                }
                .p-4 {
                    padding: 1rem;
                }
                .text-center {
                    text-align: center;
                }
                .text-2xl {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                .text-xl {
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                }
                .text-white {
                    --tw-text-opacity: 1;
                    color: rgb(255 255 255 / var(--tw-text-opacity));
                }
                .opacity-0 {
                    opacity: 0;
                }
                .outline {
                    outline-style: solid;
                }
                .transition {
                    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
                    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
                    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
                    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                    transition-duration: 0.15s;
                }
                .GameSection {
                    font-family: MontserratBlack;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                }
                .MontserratBold {
                    font-family: MontserratBold;
                }
                .MontserratRegular {
                    font-family: MontserratRegular;
                }
                .MontserratBlack {
                    font-family: MontserratBlack;
                }
                .GameSection .countdown {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 80vh;
                    margin: 0;
                    color: #fff;
                    background: none;
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    top: -20vh;
                    left: 0;
                    overflow: hidden;
                    z-index: 999;
                    text-shadow: 1px 1px 2px #000000b3, 2px 2px 4px #000000b3, 3px 3px 6px #000000b3, 4px 4px 8px #000000b3, 5px 5px 10px #000000b3;
                }
                .GameSection .countdown h2 {
                    font-size: 2rem;
                }
                .GameSection .number-countdown {
                    align-items: center;
                }
                .GameSection .number-countdown span {
                    font-size: 10rem;
                    display: inline-block;
                    opacity: 0;
                    animation: number-countdown 1.2s forwards;
                    transform: rotateY(180deg) translateZ(-100px);
                }
                .GameSection .number-countdown span:first-child {
                    animation-delay: 0s;
                }
                .GameSection .number-countdown span:nth-child(2) {
                    animation-delay: 1.2s;
                }
                .GameSection .number-countdown span:nth-child(3) {
                    animation-delay: 2.4s;
                }
                .GameSection .number-countdown span:nth-child(4) {
                    animation-delay: 3.6s;
                }
                @keyframes number-countdown {
                    0% {
                        opacity: 0;
                        transform: rotateY(120deg) translateZ(0);
                    }
                    33% {
                        opacity: 1;
                    }
                    50% {
                        opacity: 1;
                        transform: rotateY(0deg) translateZ(0);
                    }
                    66% {
                        opacity: 1;
                    }
                    to {
                        opacity: 0;
                        transform: rotateY(-66deg) translateZ(0);
                    }
                }
                .fade-in {
                    opacity: 0;
                    animation: fadeIn 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) forwards;
                }
                @keyframes fadeIn {
                    to {
                        opacity: 1;
                    }
                }
                .fade-out {
                    opacity: 1;
                    animation: fadeOut 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) forwards;
                }
                @keyframes fadeOut {
                    to {
                        opacity: 0;
                    }
                }
                @media (min-width: 640px) {
                    .sm\:max-w-\[260px\] {
                        max-width: 260px;
                    }
                    .sm\:max-w-\[380px\] {
                        max-width: 380px;
                    }
                }
            `}</style>
        </>
    );

  
    return <>{main}</>;
};

export default GameAR;
